<template>
  <v-container class="crud-content" fill-height>
    <v-col cols="12">
      <DialogWarning
        :open="backDialogOpen"
        v-on:confirm="confirm"
        v-on:cancel="cancel"
        message="Deseja cancelar as configurações das faturas?"
        screenTitle="Algumas configurações das faturas não foram salvas e serão perdidas com esta ação."
        denyDialog="VOLTAR"
        acceptDialog="SIM, CANCELAR DEFINIÇÃO"
      />
    </v-col>
    <FormController
      :showActionButtons="isAdministrator"
      title="Configurações de faturas"
      :form="form"
      :formModified="formModified"
      v-on:formSubbmit="formSubbmit"
      v-on:formCancel="formCancel"
    >
      <ConfigForm :form="form" :mask="mask" :isAdministrator="isAdministrator" />
    </FormController>
  </v-container>
</template>

<script>
import FormController from '@/components/template/form/FormController';
import DialogWarning from '@/components/template/alert/DialogWarning';
import ConfigForm from '@/views/invoice/forms/ConfigForm';
import notification from '@/service/notification';
import { routerPush, routerBack } from '@/service/kplaceRouter';
import { post, get, put } from '../../service/api';

export default {
  name: 'InvoiceConfigForm',
  data: () => ({
    isAdministrator: false,
    backDialogOpen: false,
    formModified: false,
    savedForm: null,
    form: {
      id: '',
      seller: {},
      recurringDays: null,
      closingDay: null,
      gracePeriodDay: 0,
    },
    mask: {
      toMask: true,
      patterns: [{ from: /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, to: '$1.$2.$3/$4-$5' }],
    },
  }),
  components: {
    FormController,
    ConfigForm,
    DialogWarning,
  },
  methods: {
    async init() {
      try {
        const item = localStorage.getItem('current_user');
        const groups = JSON.parse(item)?.groups;
        if (groups) {
          for (let i = 0; i < groups.length; i += 1) {
            if (groups[i].id === this.groupIdAdmin) {
              this.isAdministrator = true;
            }
          }
        }
        const { sellerId } = localStorage;
        const { data } = await get(`ui-integrator/sellers/${sellerId}`);
        this.form.seller = { ...data };
        this.form.seller.cnpj = data.cnpj.replace(this.mask.patterns[0].from, this.mask.patterns[0].to);
        if (data?.sellerFinancialAgreement) {
          this.savedForm = { ...data.sellerFinancialAgreement };
          this.savedForm.seller = { ...data };
          this.editing = true;
          this.form.gracePeriodDay = data.sellerFinancialAgreement.gracePeriodDay;
          this.form.recurringDays = data.sellerFinancialAgreement.recurringDays;
          this.form.closingDay = data.sellerFinancialAgreement.closingDay;
          this.form.id = data.sellerFinancialAgreement.id;
        }
      } catch (e) {
        notification(`Erro ao buscar configurações de faturas : ${e}`, 'error');
      } finally {
        this.laoding = false;
      }
    },
    formSubbmit() {
      if (this.editing) {
        put(`ui-integrator/financial-agreement/${this.form.id}`, { ...this.form }).then((response) => {
          if (response?.response?.status > 299) {
            notification(`${response?.response?.data.message}`, 'error');
          } else {
            routerPush('/invoice-list');
            notification('As configurações das faturas foram salvas com sucesso', 'success');
          }
        });
      } else {
        post('ui-integrator/financial-agreement/', { ...this.form }).then((response) => {
          if (response?.response?.status > 299) {
            notification(`${response?.response?.data.message}`, 'error');
          } else {
            routerPush('/invoice-list');
            notification('As configurações das faturas foram salvas com sucesso', 'success');
          }
        });
      }
    },
    formCancel() {
      if (this.form !== null && this.savedForm !== null) {
        this.formModified = !this.identicalObjects(this.form, this.savedForm);
      }
      if (this.formModified) {
        this.openBackDialog();
      } else {
        routerPush('/invoice-list');
      }
    },
    openBackDialog() {
      this.backDialogOpen = true;
    },
    cancel() {
      this.backDialogOpen = false;
    },
    confirm() {
      this.backDialogOpen = false;
      routerBack();
    },
    identicalObjects(obj1, obj2) {
      const props1 = Object.getOwnPropertyNames(obj1);
      const props2 = Object.getOwnPropertyNames(obj2);

      if (props1.length !== props2.length) {
        return false;
      }

      for (let i = 0; i < props1.length; i += 1) {
        const val1 = props1[i];
        if (val1 !== 'seller') {
          if (val1 !== '__ob__' && obj1[val1] !== obj2[val1]) {
            if (typeof obj1[val1] === 'object') {
              return this.identicalObjects(obj1[val1], obj2[val1]);
            }
            return false;
          }
        }
      }
      return true;
    },
  },
  async mounted() {
    await this.init();
  },
};
</script>

<style>
.crud-content {
  background-color: #f8f8f8;
  height: calc(100% - 160px);
  width: 100%;
  margin-top: 126px;
  border-radius: 15px;
  padding: 20px 20px 20px 20px;
}

.label-title-invoiceConfig {
  color: gray;
  font-size: 20px;
}
</style>
